import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
 
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ScistarterProfileResponse, ScistarterRecordEventResponse } from './scistarterResponse';
import sha256 from 'crypto-js/sha256';
import hex from 'crypto-js/enc-hex';

@Injectable({
  providedIn: 'root'
})
export class SciStarterService {

  constructor(private http: HttpClient) {
    this.baseUrl = 'https://scistarter.org';
    this.project_id = 7;
    this.k = '1dd42f39640542de8e2e1463dd50c403a23923f9052c1e56e69797052d0170e6f79d412b4ca5ce9f66538894a670900158939b15ac83911c56701eaff2c6bc0a';
  }

  private baseUrl: string;
  private k: string;
  private project_id = 7;

  getProfile(email: string): Observable<ScistarterProfileResponse> {
    let emailHash = sha256(email).toString(hex);
    console.log(email);
    console.log(emailHash);
    return this.http.get(this.baseUrl+`/api/profile/id?hashed=${emailHash}&key=${this.k}`)
    .pipe(map(res => <ScistarterProfileResponse>res));
  }

  recordCollectionEvent(profile_id: number): Observable<ScistarterRecordEventResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'     
      })
    };
    let body = `profile_id=${profile_id}&project_id=${this.project_id}&type=collection`;
    console.log(body);

    return this.http.post(this.baseUrl+`/api/record_event?key=${this.k}`,body, httpOptions)
    .pipe(map(res => <ScistarterRecordEventResponse>res));
  }

}
