import { Component, ChangeDetectorRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import { Phenophase } from './phenophase';
import { Species } from './species';
import { Observation } from './observation';
import { v4 as uuid } from 'uuid';
import {isNumeric} from "rxjs/util/isNumeric"
import { SciStarterService } from './scistarter.service';

/// <reference types="googlemaps"/>

interface Marker {
  lat: number;
  lng: number;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private dataService: DataService,
    private scistarterService: SciStarterService
  ) { }

  title = 'track-a-lilac';
  options = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  d = new Date();
  dateTime = this.d.toLocaleString("en-US", this.options);

  scistarterChecked = false;
  scistarterEmail: string;

  phenophases: Phenophase[];
  species: Species[];

  // used for definition popups
  phenophaseName;
  phenophaseDefinition;
  phenophaseImage;

  observationsSubmitted = false;

  streetNumber;
  route;
  thecity;
  state;
  streetAddress;
  validAddress;
  fullLocation;

  validationErrorMsg;

  map: google.maps.Map; 
  lat = 37.09024;
  lng = -95.712891;
  marker = new google.maps.Marker({
    position: {
      lat: this.lat,
      lng: this.lng
    }
  });

  @ViewChild('gmap') gmapElement: any;

  userSettings = {
    showSearchButton: false,
    inputPlaceholderText: 'Enter Location'
  }
	autoCompleteCallback1(selectedData:any) {
    // alert(JSON.stringify(selectedData));
    if(selectedData 
      && selectedData.data 
      && selectedData.data.geometry.location 
      && selectedData.data.geometry.location.lat && selectedData.data.geometry.location.lng)
      this.setMapMarker(selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng);
	}

  setPhenophase(phenophase: Phenophase, value) {
    if(!this.observationsSubmitted)
      phenophase.selection = phenophase.selection == value ? null : value;
  }

  showPhenophaseDetails(phenophase: Phenophase) {
    this.phenophaseDefinition = phenophase.definition;
    this.phenophaseName = phenophase.phenophase_name;
    if(phenophase.phenophase_name === 'breaking leaf buds')
      this.phenophaseImage = "https://www.usanpn.org/files/shared/images/phenophases/Syringa_vulgaris_emerge-leaves.jpg";
    if(phenophase.phenophase_name === 'open flowers')
      this.phenophaseImage = "https://www.usanpn.org/files/shared/images/phenophases/Syringa_vulgaris_first-bloom.jpg";
    if(phenophase.phenophase_name === 'full flowering')
      this.phenophaseImage = "https://www.usanpn.org/files/shared/images/phenophases/Syringa_vulgaris_full-bloom.jpg";
  }

  circleAllNo() {
    if(!this.observationsSubmitted)
      this.phenophases.forEach(phenophase => phenophase.selection = 'N');
  }
 
  reverseGeoCode(lat, lng) {
    let apiKey = "AIzaSyD1LZ4BL5z9hpEwolZYiPIRbW8F2OCDwqE";
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    return this.http.get(url);
  }

  public addressChange(event) {
    console.log('addresschanged');
  }

  public submitSciStarterCollection() {
    this.dataService.submitSciStarterCollection(this.scistarterEmail).subscribe(res => {
      console.log(res);
    }, err => {
      console.log(err);
    });
  }

  // public submitSciStarterCollection() {
  //   this.scistarterService.getProfile(this.scistarterEmail).subscribe(
  //     res => {
  //       console.log(res);
  //       if(res.known) {
  //         this.scistarterService.recordCollectionEvent(res.scistarter_profile_id).subscribe(
  //           res => {
  //             console.log(res);
  //           },
  //           err => {
  //             console.log("Error occured");
  //             console.log(err);
  //           }
  //         );
  //       }
        
  //     },
  //     err => {
  //       console.log("Error occured");
  //       console.log(err);
  //     }
  //   );
  // }

  public async submitObservations() {
    this.validationErrorMsg = null;

    if(this.scistarterChecked) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!this.scistarterEmail || this.scistarterEmail == '' || !re.test(String(this.scistarterEmail).toLowerCase())) {
        this.validationErrorMsg = "Your scistarter email is invalid. Please enter a valid email address."
        setTimeout(()=> { this.validationErrorMsg = null; }, 5000);
        return;
      }
    }

    if(!isNumeric(this.lat) || !isNumeric(this.lng)) {
      this.validationErrorMsg = "Your location didn't resolve to a valid latitude and longitude. Please choose a location before submitting."
      setTimeout(()=> { this.validationErrorMsg = null; }, 5000);
      return;
    }

    if(this.lat == 37.09024 && this.lng == -95.712891) {
      this.validationErrorMsg = "Please choose a location before submitting."
      setTimeout(()=> { this.validationErrorMsg = null; }, 5000);
      return;
    }

    let observationExtentMap = {'Y': 1, 'N': 0, '?': -1};
    let url = this.dataService.baseUrl + `observations`;

    let obsDate = new Date();

    let observations = [];
    this.phenophases.forEach(phenophase => {
      if (phenophase.selection != null) {
        let obs = new Observation();
        obs.observation_extent = observationExtentMap[phenophase.selection];
        obs.phenophase_id = phenophase.phenophase_id;
        obs.observation_date = obsDate.toISOString().slice(0, 19).replace('T', ' ');
        observations.push(obs);
      }
    });

    if(observations.length < 1) {
      this.validationErrorMsg = "Please select Y, N, or ? for at least one phenophase before submitting."
      setTimeout(()=> { this.validationErrorMsg = null; }, 5000);
      return;
    }

    let payload = [{
      user_id: localStorage.getItem("userId"),
      latitude: this.lat,
      longitude: this.lng,
      species_id: this.species[0].species_id,
      client_datetime: this.dateTime,
      observations: observations
    }];

    let headers = new Headers({ 'Content-Type': 'application/json' });
    //let options = new RequestOptions({ headers: headers, withCredentials: true });
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    const req = this.http.post(url, payload, httpOptions).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log("Error occured");
      }
    );
    this.observationsSubmitted = true;
    if(this.scistarterChecked)
      this.submitSciStarterCollection();
    return req;
  }

  setMapMarker(lat, lng) {
    this.marker.setMap(null);
    this.lat = lat;
    this.lng = lng;
    this.marker = new google.maps.Marker({
      position: {
        lat: this.lat, 
        lng: this.lng
      }
    });
    this.marker.setMap(this.map);
    this.map.setCenter({
      lat:this.lat,
      lng:this.lng
    });
  }

  alreadyCalled = false;

  ngOnInit() {

    if(!localStorage.getItem("userId"))
      localStorage.setItem("userId", uuid());
    var mapProp = {
      center: new google.maps.LatLng(this.lat, this.lng),
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      disableDefaultUI: true
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    
    this.marker.setMap(this.map);

    navigator.geolocation.getCurrentPosition(position => {
      console.log(position);
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      console.log('test');
      this.setMapMarker(this.lat, this.lng);
      this.reverseGeoCode(this.lat, this.lng).subscribe(geo => {
        this.streetNumber = '';
        this.route = '';
        this.thecity = '';
        this.state = '';
        if (geo['results'].length > 0) {
            geo['results'][0].address_components.forEach(ac => {
                if (ac.types.includes('street_number')) {
                    this.streetNumber = ac.short_name;
                } else if(ac.types.includes('route')) {
                    this.route = ac.short_name;
                } else if(ac.types.includes('locality')) {
                    this.thecity = ac.short_name;
                } else if(ac.types.includes('administrative_area_level_1')) {
                  this.state = ac.short_name;
                }
            });
            this.streetAddress = this.streetNumber + " " + this.route;
        } else {
            console.log("no location at coordinates");
            this.validAddress = false;
        }
        this.fullLocation = this.streetAddress + ' ' + this.thecity + ', ' + this.state;

        //update text in the input field
        this.userSettings['inputString'] = this.fullLocation;
        this.userSettings = Object.assign({},this.userSettings);

        this.cd.detectChanges();
        setTimeout(()=> { this.alreadyCalled = false; }, 200);
      });
    });

    google.maps.event.addListener(this.map, 'click', (event) => {
      if (!this.alreadyCalled) {
        this.alreadyCalled = true;
      
        this.setMapMarker(event.latLng.lat(), event.latLng.lng());
        
        this.reverseGeoCode(this.lat, this.lng).subscribe(geo => {
          this.streetNumber = '';
          this.route = '';
          this.thecity = '';
          this.state = '';
          if (geo['results'].length > 0) {
              geo['results'][0].address_components.forEach(ac => {
                  if (ac.types.includes('street_number')) {
                      this.streetNumber = ac.short_name;
                  } else if(ac.types.includes('route')) {
                      this.route = ac.short_name;
                  } else if(ac.types.includes('locality')) {
                      this.thecity = ac.short_name;
                  } else if(ac.types.includes('administrative_area_level_1')) {
                    this.state = ac.short_name;
                  }
              });
              this.streetAddress = this.streetNumber + " " + this.route;
          } else {
              console.log("no location at coordinates");
              this.validAddress = false;
          }
          this.fullLocation = this.streetAddress + ' ' + this.thecity + ', ' + this.state;

          //update text in the input field
          this.userSettings['inputString'] = this.fullLocation;
          this.userSettings = Object.assign({},this.userSettings);

          this.cd.detectChanges();
          setTimeout(()=> { this.alreadyCalled = false; }, 200);
        });

      }
      
  });

  this.dataService.getPhenophases()
    .subscribe((data) => {
    this.phenophases = data;
    });

  this.dataService.getSpecies()
    .subscribe((data) => {
    this.species = data;
    });

  }
}
